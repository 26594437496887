import { template as template_b0ca5ae683f948d895e6d64df8f27ace } from "@ember/template-compiler";
const WelcomeHeader = template_b0ca5ae683f948d895e6d64df8f27ace(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
